import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPosts } from 'src/actions';
import Page from 'src/components/Page';
import UserFlows from './UserFlows';

function Presentation() {
  const postData = useSelector((state) => state.session.posts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPosts());
    return () => {};
  }, [dispatch]);

  return (
    <Page title="Presentation">
      {postData.length > 0 ? <UserFlows index={0} content={postData[0]} /> : null }
    </Page>
  );
}

export default Presentation;
