import * as actionTypes from 'src/actions';

const initialState = {
  loggedIn: false,
  cardCheck: false,
  userData: null,
  token: null,
  authors: [],
  posts: [],
  bookings: [],
  files: [],
  currentFileFolder: [],
  currentFolderRoot: true,
  driveName: [],
  user: {
    role: 'GUEST' // ['GUEST', 'USER', 'ADMIN']
  }
};

const sessionReducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.SESSION_AUTOLOGIN: {
      // setting the user data
      console.log('auto update the store')
      return action.payload;
    }


    case actionTypes.SESSION_LOGIN: {
      // saving login data to storage
      localStorage.setItem('h07_lng', JSON.stringify({
        ...state,
        loggedIn: true,
        userData: action.payload.userInfo,
        userMeta: action.payload.userMeta,
        token: action.payload.token,
        user: {
          first_name: action.payload.userInfo.data.display_name,
          last_name: '',
          email: action.payload.userInfo.data.user_email,
          avatar: '/images/avatars/avatar_11.png',
          bio: action.payload.userInfo.data.user_login,
          role: action.payload.userInfo.roles[0] === 'subscriber' ? 'USER' : 'ADMIN' // ['GUEST', 'USER', 'ADMIN']
        }}));
      // setting the user data
      return {
        ...state,
        loggedIn: true,
        userData: action.payload.userInfo,
        userMeta: action.payload.userMeta,
        token: action.payload.token,
        user: {
          first_name: action.payload.userInfo.data.display_name,
          last_name: '',
          email: action.payload.userInfo.data.user_email,
          avatar: '/images/avatars/avatar_11.png',
          bio: action.payload.userInfo.data.user_login,
          role: action.payload.userInfo.roles[0] === 'subscriber' ? 'USER' : 'ADMIN' // ['GUEST', 'USER', 'ADMIN']
        }
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        token: null,
        userData: null,
        user: {
          role: 'GUEST'
        }
      };
    }

    case actionTypes.CARD_CHECK: {
      return {
        ...state,
        cardCheck: true
      };
    }

    case actionTypes.GET_POSTS: {
      // setting the user data
      return {
        ...state,
        posts: action.payload,
      };
    }

    case actionTypes.GET_BOOKINGS: {
      // setting the user data
      return {
        ...state,
        bookings: action.payload,
      };
    }

    case actionTypes.GET_AUTHORS: {
      // setting the user data
      return {
        ...state,
        authors: action.payload,
      };
    }

    case actionTypes.DRIVE_LIST: {
      // setting the user data
      return {
        ...state,
        files: action.payload.files,
        currentFileFolder: action.payload.parent,
        currentFolderRoot: action.payload.isRoot,
      };
    }

    case actionTypes.DRIVE_NAME: {
      return {
        ...state,
        driveName: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
