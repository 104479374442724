import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  divider: { opacity: 0 }
}));

function Spacer({ h = '100px' }) {
  const classes = useStyles();

  return (
    <Divider className={classes.divider} style={{ height: h }} />
  );
}

Spacer.propTypes = {
  h: PropTypes.string
};

export default Spacer;
