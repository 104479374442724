import { colors } from '@material-ui/core';

export default {
  root: {
    backgroundColor: colors.black,
    '&:hover': {
      backgroundColor: colors.black
    }
  }
};
