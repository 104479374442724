import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.config.layout.primary.background,
    minHeight: '100vh',
  },
  header: {
    width: theme.breakpoints.values.md,
    backgroundColor: theme.config.layout.primary.background,
    color: theme.config.layout.primary.contrastText,
    maxWidth: '100%',
    margin: '0 auto',
    padding: '80px 24px',
    [theme.breakpoints.up('md')]: {
      padding: '160px 24px'
    }
  },
  buttons: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  },
  mediaContainer: {
    margin: '0 auto',
    maxWidth: 1600,
    padding: theme.spacing(0, 2),
    overflow: 'hidden'
  },
  media: {
    width: '100%'
  },
  statsInner: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    margin: '0 auto'
  }
}));

function Header({ className, ...rest }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.header}>
        <Typography
          align="center"
          gutterBottom
          variant="h1"
          dangerouslySetInnerHTML={{ __html: theme.config.strings.validateEmailTitle }}
        />
        <Typography
          align="center"
          component="h2"
          variant="subtitle1"
          dangerouslySetInnerHTML={{ __html: theme.config.strings.validateEmailText }}
        />
        <div className={classes.buttons}>
          <Button
            color="primary"
            component={RouterLink}
            to="/auth/login"
            variant="contained"
          >
            {theme.config.strings.validateEmailLabel}
          </Button>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
