import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import PresentationView from './views/Presentation';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/recover',
        exact: true,
        component: lazy(() => import('src/views/Login3'))
      },
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Login2'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('src/views/Register2'))
      },
      {
        path: '/auth/logout',
        exact: true,
        component: lazy(() => import('src/views/Logout'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/user',
        exact: true,
        component: lazy(() => import('src/views/Register3'))
      },
      {
        path: '/dashboard',
        exact: true,
        component: PresentationView
      },
      {
        path: '/drive/',
        exact: true,
        component: lazy(() => import('src/views/ProjectDetails2'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];
