import { colors } from '@material-ui/core';

const white = '#ffffff';
const black = '#333';

export default {
  primary: {
    contrastText: white,
    dark: black,
    main: colors.grey[900],
    light: colors.indigo[100]
  },
  secondary: {
    contrastText: colors.grey[400],
    dark: colors.grey[900],
    main: colors.grey[900],
    light: colors.grey[900]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.grey[600],
    secondary: colors.grey[400],
    link: colors.grey[600]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: colors.grey[200],
    paper: colors.grey[300]
  },
  divider: '#707070',
  black,
  button: {
    width: 'auto',
  },
  spiga: {
    body: '#1f1f22',
    red: '#ff0000',
    white: '#fff',
    gray: '#757575',
    black: '#000',
    calendar: '#1a1a1a',
    buttonBackgroundHoverColor: '#212121',
    card: {
      backgroundColor: '#1f1f22',
      border: '1px solid #707070',
      borderRadius: '15px'
    },
    typos: {
      color: '#707070',
    },
    fields: {
      borderRadius: '5px',
    },
    button: {
      width: 'auto',
      padding: '2px 10px',
      borderRadius: '30px',
      backgroundColor: '#000',
      color: '#fff',
      '& > .MuiButton-label': {
        fontSize: '12px',
        fontWeight: 700,
        color: '#fff'
      },
      '&:hover': {
        backgroundColor: '#212121',
      }
    },
  },
  ciam: {
    body: '#1f1f22',
    red: '#ff0000',
    white: '#fff',
    gray: '#757575',
    black: '#000',
    calendar: '#1a1a1a',
    buttonBackgroundHoverColor: '#212121',
    topbar: {
      backgroundColor: '#ffffff',
    },
    card: {
      backgroundColor: '#ffffff',
      border: '1px solid #d09882',
      borderRadius: '15px',
      color: '#000000',
    },
    typos: {
      color: '#707070',
    },
    fields: {
      borderRadius: '5px',
    },
    button: {
      width: 'auto',
      padding: '2px 10px',
      borderRadius: '30px',
      backgroundColor: '#000',
      color: '#fff',
      '& > .MuiButton-label': {
        fontSize: '12px',
        fontWeight: 700,
        color: '#fff'
      },
      '&:hover': {
        backgroundColor: '#212121',
      }
    },
  }
};
