export default [
  {
    items: [
      {
        title: 'Dashboard',
        href: '/dashboard',
      },
      {
        title: 'Drive',
        href: '/drive',
      },
      {
        title: 'Documents',
        href: 'https://ciamweb.it/it/post-vendita-documentilib',
      },
      {
        title: 'Profile',
        href: '/user',
      },
    ]
  }
];
