import config from './config.json';

export const ROOTAPI = (window.ROOTAPI ? window.ROOTAPI : config.rootAPI);
export const ROOTAPI_SIGNIN = '/wp-json/jwt-auth/v1/token';
export const ROOTAPI_SIGNON = '/wp-json/users/create';
export const ROOTAPI_RECOVER = '/wp-json/users/recover';
export const ROOTAPI_ACTIVATE = '/user-activation/?act={TOKEN}&output=json';
export const ROOTAPI_SENDACTIVATION = '/wp-json/users/sendvalidationmail';

export const ROOTAPI_GETPOSTS = '/wp-json/wp/v2/posts';
export const ROOTAPI_GETMEDIA = '/wp-json/wp/v2/media/';
export const ROOTAPI_GETBOOKINGS = '/wp-json/calendario/v1/eventi/list';
export const ROOTAPI_ADDBOOKINGS = '/wp-json/calendario/v1/eventi/create';
export const ROOTAPI_APPROVEBOOKINGS = '/wp-json/calendario/v1/eventi/confirm';
export const ROOTAPI_DELETEBOOKING = '/wp-json/calendario/v1/eventi/delete';
export const ROOTAPI_ADDADMINBOOKINGS = '/wp-json/calendario/v1/eventi/admincreate';
export const ROOTAPI_UPDATEADMINBOOKINGS = '/wp-json/calendario/v1/eventi/adminupdate';
export const ROOTAPI_GETAUTHORS = '/wp-json/calendario/v1/eventi/getauthors';

export const ROOTAPI_DRIVE_LIST = '/wp-json/extrapi/v2/drive/bGlzdA=='; // '/wp-json/extrapi/v2/drive/list' (list in base 64)
export const ROOTAPI_DRIVE_SEARCH = '/wp-json/extrapi/v2/drive/search';
export const ROOTAPI_DRIVE = '/wp-json/extrapi/v2/drive/'; // + folder ID in base 64
export const ROOTAPI_DRIVE_GET = '/wp-json/extrapi/v2/drive-print/'; // + item ID  in base 64
export const ROOTAPI_DRIVE_DOWNLOAD = '/wp-json/extrapi/v2/drive-get/'; // + item ID in base 64

export const XHR_API = (url, params, method = 'POST', cb = () => {}) => {
  const http = new XMLHttpRequest();
  http.open(method, url, true);

  http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

  http.onreadystatechange = () => {
    if (http.readyState === 4 && http.status === 200) {
      cb(http.responseText);
    }
  };

  http.send(params);
};
