import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Container, Grid, Typography, Divider, Button, useTheme
} from '@material-ui/core';
import Spacer from '../../components/Spacer';
import { ROOTAPI, ROOTAPI_GETMEDIA } from '../../Setup';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6)
  },
  media: {
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  newsDate: {
    color: theme.config.layout.primary.contrastText,
    fontSize: '18px',
    fontWeight: 400,
  },
  newsTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: theme.config.layout.primary.contrastText,
  },
  newsText: {
    color: theme.config.layout.primary.contrastText,
    fontSize: '16px',
  },
  submitButton: {
    marginTop: theme.spacing(2),
    ...theme.config.layout.button
  },
}));

function UserFlows({ className, ...rest }) {
  const classes = useStyles();
  const theme = useTheme();
  const [cover, setCover] = useState(false);

  const stripHtml = (html) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  useEffect(() => {
    if (rest.content.featured_media) {
      Axios.get(`${ROOTAPI}${ROOTAPI_GETMEDIA}${rest.content.featured_media}`)
        .then((response) => {
          setCover(response.data.media_details.sizes.medium.source_url);
          console.log('Axios getPosts >>>', response.data);
        }, (error) => {
          console.log('Axios getPosts Error >>>', error);
        });
    }
    return () => {};
  }, [rest.content.featured_media]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Container maxWidth="lg">
        <Grid
          alignItems="center"
          container
          justify="space-between"
        >
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
          >
            <Typography
              gutterBottom
              variant="h3"
              className={classes.newsDate}
            >
              {moment(rest.content.date).format('MMMM, YYYY')}
            </Typography>
            <Typography
              gutterBottom
              variant="h1"
              className={classes.newsTitle}
            >
              {rest.content.title.rendered}
            </Typography>
            <Typography variant="subtitle2" className={classes.newsText}>
              {stripHtml(rest.content.excerpt.rendered)}
              <Spacer h="30px" />
              <Divider className={classes.divider} />
            </Typography>
          </Grid>
          <Grid
            className={classes.media}
            item
            lg={6}
            md={6}
            xs={12}
          >
            {
            cover ? (<img alt="cover" src={cover} />) : null
          }
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid
          alignItems="center"
          container
          justify="space-between"
        >
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
          >
            <Typography
              gutterBottom
              variant="h1"
              className={classes.newsTitle}
              dangerouslySetInnerHTML={{ __html: theme.config.strings.ctaDrive }}
            />
          </Grid>
          <Grid
            className={classes.media}
            item
            lg={6}
            md={6}
            xs={12}
          />
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            {' '}
            <Button
              className={classes.submitButton}
              color="secondary"
              size="large"
              type="submit"
              variant="contained"
              component={RouterLink}
              to="/drive"
            >
              {theme.config.strings.driveLabel}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>

  );
}

UserFlows.propTypes = {
  className: PropTypes.string
};

export default UserFlows;
