/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import Validation from './views/Presentation2';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/auth/login" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/recover',
        exact: true,
        component: lazy(() => import('src/views/Login3'))
      },
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Login2'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('src/views/Register2'))
      },
      {
        component: () => <Redirect to="/auth/login" />
      }
    ]
  },
  {
    path: '/user-validation',
    exact: true,
    component: () => <Validation />
  },
  {
    component: () => <Redirect to="/auth/login" />
  }
];
