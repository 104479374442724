import React from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { theme } from './theme';
import { configureStore } from './store';
import routes from './routes';
import routesNoAuth from './routes-noAuth';
import GoogleAnalytics from './components/GoogleAnalytics';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/main.scss';

const history = createBrowserHistory();
const store = configureStore();

const RenderTheRoutes = () => {
  const isAuth = useSelector((state) => state.session.loggedIn);
  return isAuth ? renderRoutes(routes) : renderRoutes(routesNoAuth);
};

function App() {
  return (
    <Provider store={store}>
      <GoogleReCaptchaProvider reCaptchaKey={theme.config.recaptchaSiteKey}>
        <ThemeProvider theme={theme}>
          <StylesProvider direction="ltr">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Router history={history}>
                <ScrollReset />
                <GoogleAnalytics />
                <RenderTheRoutes />
              </Router>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </GoogleReCaptchaProvider>
    </Provider>
  );
}

export default App;
