import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Page from 'src/components/Page';
import Header from './Header';

const useStyles = makeStyles(() => ({
  root: {}
}));

function Presentation() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Validate your email"
    >
      <Header />
    </Page>
  );
}

export default Presentation;
