import Axios from 'axios';
import {
  ROOTAPI, ROOTAPI_SIGNIN, ROOTAPI_SIGNON, ROOTAPI_GETPOSTS, ROOTAPI_GETBOOKINGS, ROOTAPI_ADDBOOKINGS, ROOTAPI_GETAUTHORS, ROOTAPI_ADDADMINBOOKINGS, ROOTAPI_DELETEBOOKING, ROOTAPI_UPDATEADMINBOOKINGS, ROOTAPI_APPROVEBOOKINGS, ROOTAPI_DRIVE_LIST, ROOTAPI_DRIVE
} from '../Setup';
import config from '../config.json';

export const SESSION_AUTOLOGIN = 'SESSION_AUTOLOGIN';
export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const CARD_CHECK = 'CARD_CHECK';
export const GET_POSTS = 'GET_POSTS';
export const GET_BOOKINGS = 'GET_BOOKINGS';
export const ADD_BOOKINGS = 'ADD_BOOKINGS';
export const APPROVE_BOOKINGS = 'APPROVE_BOOKINGS';
export const DELETE_BOOKINGS = 'DELETE_BOOKINGS';
export const ADD_ADMINBOOKINGS = 'ADD_ADMINBOOKINGS';
export const UPDATE_ADMINBOOKINGS = 'UPDATE_ADMINBOOKINGS';
export const GET_AUTHORS = 'GET_AUTHORS';
export const SET_MESSAGE = 'SET_MESSAGE';

export const DRIVE_LIST = 'DRIVE_LIST';
export const DRIVE_NAME = 'DRIVE_NAME';

export const autoLogin = (obj) => (dispatch) => {
  dispatch({
    type: SESSION_AUTOLOGIN,
    payload: obj
  });
};

export const login = (obj) => (dispatch) => {
  Axios.post(`${ROOTAPI}${ROOTAPI_SIGNIN}`, obj)
    .then((response) => {
      if (response.data.code === 'jwt_auth_failed') {
        window.alert(`You are no more able to login, contact ${config.company} for informations`);
      } else {
        dispatch({
          type: SESSION_LOGIN,
          payload: response.data
        });
      }
    }, (error) => {
      console.log('Axios Error >>>', error);
      alert('Login error');
      dispatch({
        type: SESSION_LOGOUT
      });
    });
};

export const logout = () => (dispatch) => dispatch({
  type: SESSION_LOGOUT
});

export const cardCheck = () => (dispatch) => dispatch({
  type: CARD_CHECK
});

export const registration = (obj) => () => {
  Axios.post(`${ROOTAPI}${ROOTAPI_SIGNON}`, obj)
    .then((response) => {
      if (response.data.success === false) {
        alert(`Your request cannot be processed. ${response.data.message}`);
      }
    }, (error) => {
      console.log('Axios Error >>>', error);
    });
};

export const getPosts = () => (dispatch) => {
  Axios.get(`${ROOTAPI}${ROOTAPI_GETPOSTS}`)
    .then((response) => {
      dispatch({
        type: GET_POSTS,
        payload: response.data
      });
    }, (error) => {
    });
};

export const getBookings = (obj) => (dispatch) => {
  Axios.get(`${ROOTAPI}${ROOTAPI_GETBOOKINGS}?nounce=${obj.nounce}&user_id=${obj.user_id}&token=${obj.token}`)
    .then((response) => {
      dispatch({
        type: GET_BOOKINGS,
        payload: response.data.posts
      });
    }, (error) => {
      console.log('Axios getPosts Error >>>', error);
    });
};

export const addBookings = (obj) => (dispatch) => {
  Axios.post(`${ROOTAPI}${ROOTAPI_ADDBOOKINGS}`, obj)
    .then((response) => {
      console.log('Axios addBookings >>>', response.data);

      Axios.get(`${ROOTAPI}${ROOTAPI_GETBOOKINGS}?nounce=${obj.nounce}&user_id=${obj.user_id}&token=${obj.token}`)
        .then((response2) => {
          dispatch({
            type: GET_BOOKINGS,
            payload: response2.data.posts
          });
        }, (error) => {
          console.log('Axios getPosts Error >>>', error);
        });
    }, (error) => {
      console.log('Axios addBookings Error >>>', error);
    });
};

export const addAdminBookings = (obj) => (dispatch) => {
  Axios.post(`${ROOTAPI}${ROOTAPI_ADDADMINBOOKINGS}`, obj)
    .then((response) => {
      console.log('Axios addAdminBookings >>>', response.data);
      Axios.get(`${ROOTAPI}${ROOTAPI_GETBOOKINGS}?nounce=${obj.nounce}&user_id=${obj.user_id}&token=${obj.token}`)
        .then((response2) => {
          console.log('Axios getPosts >>>', response2.data);
          dispatch({
            type: GET_BOOKINGS,
            payload: response2.data.posts
          });
        }, (error) => {
          console.log('Axios getPosts Error >>>', error);
        });
    }, (error) => {
      console.log('Axios addAdminBookings Error >>>', error);
    });
};

export const getAuthors = () => (dispatch) => {
  Axios.get(`${ROOTAPI}${ROOTAPI_GETAUTHORS}`)
    .then((response) => {
      console.log('Axios getAuthors >>>', response.data);
      dispatch({
        type: GET_AUTHORS,
        payload: response.data.posts
      });
    }, (error) => {
      console.log('Axios getAuthors Error >>>', error);
    });
};

export const deleteBookings = (obj) => (dispatch) => {
  Axios.post(`${ROOTAPI}${ROOTAPI_DELETEBOOKING}`, obj)
    .then((response) => {
      console.log('Axios deleteBookings >>>', response.data);

      Axios.get(`${ROOTAPI}${ROOTAPI_GETBOOKINGS}?nounce=${obj.nounce}&user_id=${obj.user_id}&token=${obj.token}`)
        .then((response2) => {
          console.log('Axios getPosts >>>', response2.data);
          dispatch({
            type: GET_BOOKINGS,
            payload: response2.data.posts
          });
        }, (error) => {
          console.log('Axios getPosts Error >>>', error);
        });
    }, (error) => {
      console.log('Axios deleteBookings Error >>>', error);
    });
};

export const approveBookings = (obj) => (dispatch) => {
  Axios.post(`${ROOTAPI}${ROOTAPI_APPROVEBOOKINGS}`, obj)
    .then((response) => {
      console.log('Axios approveBookings >>>', response.data);

      Axios.get(`${ROOTAPI}${ROOTAPI_GETBOOKINGS}?nounce=${obj.nounce}&user_id=${obj.user_id}&token=${obj.token}`)
        .then((response2) => {
          console.log('Axios getPosts >>>', response2.data);
          dispatch({
            type: GET_BOOKINGS,
            payload: response2.data.posts
          });
        }, (error) => {
          console.log('Axios getPosts Error >>>', error);
        });
    }, (error) => {
      console.log('Axios approveBookings Error >>>', error);
    });
};

export const updateAdminBookings = (obj) => (dispatch) => {
  Axios.post(`${ROOTAPI}${ROOTAPI_UPDATEADMINBOOKINGS}`, obj)
    .then((response) => {
      console.log('Axios addAdminBookings >>>', response.data);

      Axios.get(`${ROOTAPI}${ROOTAPI_GETBOOKINGS}?nounce=${obj.nounce}&user_id=${obj.user_id}&token=${obj.token}`)
        .then((response2) => {
          console.log('Axios getPosts >>>', response2.data);
          dispatch({
            type: GET_BOOKINGS,
            payload: response2.data.posts
          });
        }, (error) => {
          console.log('Axios getPosts Error >>>', error);
        });
    }, (error) => {
      console.log('Axios addAdminBookings Error >>>', error);
    });
};

export const driveDoList = (obj) => (dispatch) => {
  if (obj.deeplink) {
    Axios.get(`${ROOTAPI}${ROOTAPI_DRIVE}${obj.code}?user=${obj.user}&token=${obj.token}&deeplink=${obj.deeplink}`)
      .then((response) => {
        let currentFlow = obj.currentFlow || [];
        currentFlow.push(response.data.parent);
        if (response.data.bc && response.data.bc.length > 0) {
          currentFlow = response.data.bc;
        }
        dispatch({
          type: DRIVE_LIST,
          payload: {
            files: response.data.ls, parent: currentFlow, isRoot: response.data.isRoot, bc: response.data.bc
          }
        });
      }, (error) => {
        console.log('DriveDoList Error (Deeplink):', error);
      });
  } else {
    Axios.get(`${ROOTAPI}${ROOTAPI_DRIVE_LIST}?user=${obj.user}&token=${obj.token}`)
      .then((response) => {
        const currentFlow = obj.currentFlow || [];
        currentFlow.push(response.data.parent);
        dispatch({
          type: DRIVE_LIST,
          payload: {
            files: response.data.ls, parent: currentFlow, isRoot: response.data.isRoot
          }
        });
      }, (error) => {
        console.log('DriveDoList Error:', error);
      });
  }
};

export const driveDoLs = (obj) => (dispatch) => {
  Axios.get(`${ROOTAPI}${ROOTAPI_DRIVE}${obj.code}?user=${obj.user}&token=${obj.token}`)
    .then((response) => {
      let currentFlow = obj.currentFlow || [];
      if (currentFlow.indexOf(response.data.parent) === -1) {
        currentFlow.push(response.data.parent);
      } else {
        currentFlow = currentFlow.slice(0, currentFlow.indexOf(response.data.parent));
        currentFlow.push(response.data.parent);
      }

      dispatch({
        type: DRIVE_LIST,
        payload: {
          files: response.data.ls, parent: currentFlow, isRoot: response.data.isRoot
        }
      });
    }, (error) => {
      console.log('Axios driveDoLs Error >>>', error);
    });
};

export const setDriveName = (obj) => (dispatch) => {
  let driveName = obj.driveName || [];
  if (driveName.indexOf(obj.name) !== -1) {
    driveName = driveName.slice(0, driveName.indexOf(obj.name));
  }
  driveName.push(obj.name);

  dispatch({
    type: DRIVE_NAME,
    payload: driveName
  });
};
